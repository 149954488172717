
@keyframes scanlines{
  0%{
    top: 20px;
  }100%{
    top: 0px;
  }
}

.scanlines{
  position: absolute;
  height: 100%;
  width: 100%;
  background: url('./assets/images/scanlines.png') repeat;
  transform: scale(1.1);
  z-index: 999;
  animation: scanlines 1.6s linear infinite forwards;
  pointer-events: none;
  filter: brightness(1.5);
  overflow: hidden;
}

.vignette {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  box-shadow: inset 0 0 3em 1em #000;
  z-index: 600;
  pointer-events: none;
  overflow: hidden;
  opacity: .5;
}

.App {
  /* background-color: red; */
  width: 100%;
  height: 100vh;
  margin-top: 20px;
  padding-bottom: 100px;
  overflow-y: scroll;
}





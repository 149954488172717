.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-color: var(--transparent-black);
    border-bottom: .2rem outset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    z-index: 998;
    backdrop-filter: blur(3.5px);
}

/* default nav style */
.nav-text {
    padding-left: .6rem;
    /* color: var(--windows-teal); */
    text-shadow: 1px 1px 1px var(--windows-black);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7rem;
}

/* when nav opens */
.nav-text-nav {
    padding-left: .6rem;
    text-shadow: 1px 1px 1px var(--windows-black);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .7rem;
    /* animation: flicker 1s infinite;
    animation-delay: 2s; */
}

.navbar-container img {
    padding-right: 5px;
    padding-top: 3px;
    height: 35px;
}

.navbar-container img:hover {
    cursor: pointer;
}

.open-menu-btn {
    padding-right: 8px;
    font-size: .8rem;
    /* color: var(--windows-teal); */
    text-shadow: 1px 1px 1px var(--windows-black);
    animation: flickerGrow 1.8s infinite;
    cursor: pointer;
    transition: all ease-in-out .4s;
}

.open-menu-btn:hover {
    filter: brightness(1.5);
}

.open-menu-btn-active {
    padding-right: 8px;
    font-size: .8rem;
    /* color: var(--windows-teal); */
    text-shadow: 1px 1px 1px var(--windows-black);
    animation: flicker 2s infinite;
    cursor: pointer;
    /* color: var(--windows-gray); */
}



.home-container {
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    text-align: center;
    padding-bottom: 12rem;
    top: 0;
}

.home-welcome-banner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: .7rem;
    padding-bottom: .7rem;
}

.home-welcome-banner-container h1 {
    padding: 1rem 0rem .2rem .8rem;
    align-self: baseline;
    /* font-size: 1.6rem; */
}

.home-bio-banner-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-overflow: clip;
    padding-top: 3rem;
    padding-bottom: 1rem;
    /* font-family: monospace; */
}

.home-bio-banner-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 50%;
}

.home-bio-banner-left img {
   max-width: 100%;
   /* padding: .7rem; */
   padding-top: 0;
}

.home-bio-banner-left p{
    text-align: left;
    font-size: 1rem;
    line-height: 1.4rem;
    padding: .5rem;
    padding-left: 1.8rem;
    text-shadow: 3px 3px 1px black, 0px 0px 2rem rgba(255, 255, 255, 0.557);
}

.home-bio-banner-right {
    position: relative;
    height: 100%;
    width: 100%;
}

.sideways-text {
    position: absolute;
    font-size: 2rem;
    bottom: 50%;
    rotate: -90deg;
    transform: scale(2,3);
    text-shadow: 3px 3px 0px black;
    right: 1rem;
    /* color: blue; */
}


.homepage-paragraph {
    font-size: .6rem;
    padding: 1rem;
    padding-top: 1.3rem;
    line-height: 1.3rem;
    text-align: center;
    text-shadow: 2px 2px 0px black;
}

.homepage-paragraph a {
    color: rgb(246, 255, 0);
    animation: flicker 2s infinite;
}



/* DESKTOP VERSION */

@media only screen and (min-width: 1024px) {

    .home-welcome-banner-container {
        padding-left: 2rem;
        font-size: 2rem;
    }

    .home-bio-banner-container {
        display: none;
    }


  }
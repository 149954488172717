.menu-modal-container {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.menu-modal-box {
    height: 80%;
    width: 90%;
    background-color: var(--transparent-black);
    backdrop-filter: blur(7px);
    border: 5px outset;
    box-shadow: 4px 4px 2px black;
}

.menu-modal-navbar {
    height: 30px;
    background-color: rgba(0, 0, 0, 0.862);
    /* backdrop-filter: blur(2px); */
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--windows-white);
    padding: 5px;
    border-bottom: 4px outset;
}

.menu-modal-navbar p {
    font-weight: 400;
    font-size: .6rem;
    text-shadow: none;
}

.menu-modal-navbar img {
    background-color: rgb(14, 200, 191);
    padding: 3px;
    border: 2px outset;
    filter: invert();
    animation: flicker 1.3s infinite;
}

.menu-modal-navbar img:hover {
    cursor: pointer;
}

.menu-modal-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    padding: 12px;
    border: 3px inset;
    height: calc(100% - 30px);
}

.menu-modal-link {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8px;
    font-size: .6rem;
}

.menu-modal-link img {
    height: 65px;
    padding: 5px;
}


.menu-modal-link p {
    /* filter: invert(); */
    text-shadow: 3px 3px 2px black;
}
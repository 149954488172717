.contact-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    padding-top: 6rem;
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    font-size: .8rem;
    text-shadow: 4px 4px 2px black;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    width: 100%;
    margin-top: 2rem;
  }
  
  .contact-form label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    padding: .8rem;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: .6rem;
    font-size: 1.2rem;
    border-radius: 0.3rem;
    border: none;
    margin-bottom: .5rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 4px 4px 2px black;
  }
  
  .contact-form button {
    background-color: var(--windows-light-gray);
    color: white;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    border: none;
    border-radius: 0.3rem;
    cursor: pointer;
    margin-top: 1rem;
    font-family: 'Press Start 2P', monospace;
    box-shadow: 4px 4px 2px black;
    /* text-shadow: 4px 4px 2px black; */
  }
  
.photos-page-container {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-y: scroll;
}

.photos-page-container img {
    max-width: 100%;
}


@media only screen and (min-width: 768px) {
    .photos-page-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
.footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-color: var(--transparent-black);
    backdrop-filter: blur(3.5px);
    border-top: .2rem outset ;
    z-index: 995;
    margin: 0;
    padding: 0;
}
.socials-box-container {
    width: 100%;
    margin-top: .5rem;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.download-resume-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--transparent-black);
    font-size: .7rem;
    padding: .7rem;
    width: 70%;
    border-radius: .5rem;
    transition: all .4s ease-in-out;
    margin-top: 1rem;
}

.download-resume-btn:hover {
    background-color: rgba(255, 255, 255, 0.418);
    color: black;
}

.socials-box-container h1 {
    font-size: .9rem;
    text-shadow: var(--small-shadow);
}

.socials-box-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: .5rem;
}

.socials-grid-item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.socials-grid-item img {
    max-width: 80%;
    margin-bottom: .5rem;

}

.socials-grid-item p {
    font-size: .6rem;
    padding: 2px;
    padding-bottom: .5rem;
}



@media only screen and (min-width: 1024px) {
    .socials-box-container {
        padding-top: 2rem;
        width: 40%;
    }
    .socials-box-grid {
        padding: 1rem;
    }
    .download-resume-btn {
        margin-top: 0;
    }
}
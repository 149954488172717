:root {
  --windows-black: #000000;
  --windows-gray: #818181;
  --windows-light-gray: #c3c3c3;
  --windows-white: #fdffff;
  --windows-teal: #00807F;
  --windows-blue: #010081;

  --transparent-black: rgba(0, 0, 0, 0.447);

  --main-color: #21697f;
  --secondary-color: #8897DB;
  --text-color: black;

  --big-shadow: 4px 4px 0px black; 
  --small-shadow: 2px 2px 1px black;

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  height: 100vh;
  width: 100%;
}

body {
  font-family: 'Press Start 2P', monospace;
  height: 100%;
  width: 100%;
  background-color: var(--windows-teal);
  background-color: var(--windows-black);
  background-image: url('./assets/backgrounds/GreenGradient.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: white;
}

/* DESKTOP SCREENSAVER */
@media only screen and (min-width: 1024px) {
  body {
    background-image: url('./assets/backgrounds/greenGradientDesktop.png');
  }
}

h1 {
  text-shadow: var(--big-shadow);
}

hr {
  border: 1px solid var(--windows-white);
  box-shadow: var(--big-shadow);
  width: 80%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  text-shadow: 3px 3px 2px black;
}

.blankspace {
  min-height: 20rem;
  width: 100%;
  /* background-color: red; */
}

@keyframes flicker {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes flickerGrow {
    0% {
      opacity: 1;
      transform: scale(.95) rotate(0deg);
    }
    50% {
      transform: scale(1.03) rotate(0deg);
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6), 1px 1px 1px black;
    }
    100% {
      opacity: 1;
      transform: scale(.95) rotate(0deg);
    }
  }

  @keyframes glow {
    0% {
      text-shadow: 0px 0px 12px var(--windows-white), 3px 3px 0px black;
    }
    50% {
      text-shadow: 0px 0px 18px var(--windows-white), 3px 3px 0px black;
    }
    100% {
      text-shadow: 0px 0px 12px var(--windows-white), 3px 3px 0px black;
    }
  }
.clock-container {
    font-family: "Press Start 2P", cursive;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    filter: brightness(1.3);
    align-self: first baseline;
  }
  
  
  .clock-container h1 {
    justify-self: left;
    font-size: .8rem;
    margin: 0;
    padding-top: 6px;
    padding: .3rem;
    padding-left: .85rem;
    letter-spacing: 5px;
    /* line-height: 2rem; */
    text-shadow: 3px 3px 0px black;
    animation: none;
  }

  @media only screen and (min-width: 1024px) {
    .clock-container h1 {
      font-size: 1.6rem;
    }
  }
  